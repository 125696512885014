import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'
import { LoadingOverlay } from 'components/common'
import { descompressResponse } from 'utils/decompress'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Auth } from 'aws-amplify'


const initialState = {
  user: null,
  refreshToken: '',
  idToken: ''
}

const KEY_PREFIX_PROVIDER = 'CognitoIdentityServiceProvider.keyPrefix'

export const UserContext = React.createContext(initialState)

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [settings, setSettings] = useState(null)
  const [authState, setAuthState] = useState(null)
  const [refreshToken, setRefreshToken] = useState('')
  const [idToken, setIdToken] = useState('')
  const [isLoading, setLoading] = useState(true)
  const { t } = useTranslation()

  const signOut = async () => {
 
      // setUser(null)
      setRefreshToken('')
      setIdToken('')

      setAuthState(null)
 
      try {
        await Auth.signOut()
      } catch (e) {
        console.log(e)
      }

    }

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': idToken + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
  })

  const authApi = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': idToken + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
  })

  const cognitoApi = axios.create({
    baseURL: `https://cognito-idp.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com`,
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1',
    },
  })


  const logInstance = axios.create({
    baseURL: process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT,
  })

  const registryLog = (click, url)  => {
    new Promise((resolve, reject) => {
      console.log(process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT)
      if (currentUser) {
        logInstance.post(
          '/access-ctrl/log',
          {
            type: 'access',
            user: {
              userId: currentUser.email
            },
            functionality: url,
            application: 'smart',
            message: click
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then(response => {
          if (response.status === 200) {
            console.log('Log salvo')
          }
          else {
            reject(new Error('Couldn\'t retrieve data'))
          }
        })
          .catch(error => {
            reject(error)
          })
      }
    })
  
  }


  const getUserApps = (token) => {
    return new Promise((resolve, reject) => {
      if (!currentUser) {
        const headerToken = { 'Authorization': token + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID })) }
        authApi.post('/user/apps', {}, { headers: headerToken })
          .then((response) => {
            const responseData = response.data ? response.data : response
            const data = descompressResponse(responseData)
            setCurrentUser(data.user)
            setSettings(data.rootSettings)
            console.log(data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const updateProfile = async (user) => {
    const params = {update: user}
    return new Promise((resolve, reject) => {
      const headerToken = { 'Authorization': idToken }
      authApi.post('/user/update-profile', params, { headers: headerToken })
        .then((response) => {
          resolve(response.data.body)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const helpClientEmail = async (formState) => {
    console.log(process.env.REACT_APP_AUTH_URL)
    const params = {formState}
    return new Promise((resolve, reject) => {
      const headerToken = { 'Authorization': idToken }
      authApi.post('/user/help-client-email', params, { headers: headerToken })
        .then((response) => {
          resolve(response.data.body)
          toast(t('help.success'))
        })
        .catch((error) => {
          toast(t('help.fail'))
          reject(error)
        })
    })
  }


  useEffect(() => {
    const hydrate = () => new Promise((resolve, reject) => {
      const query = new URLSearchParams(window.location.search)
      Auth.currentUserPoolUser().then((user) => {
        if (query.get('kp') || query.get('rt') || query.get('dk') || query.get('lu')) {
          setAuthState('RELOAD')  
          resolve() 
        } else {
          getUserApps(user.signInUserSession.idToken.jwtToken).then(() => {
            // setUser(user)
            setIdToken(user.signInUserSession.idToken.jwtToken)
            setRefreshToken(user.signInUserSession.refreshToken.token)          
            setAuthState('SIGNED_IN')           
            Cookies.set('signedIn', true, { domain: process.env.REACT_APP_DOMAIN }) 
            resolve()    
          }).catch((error) => {
            reject(error)
          })  
        }          
      }).catch(err => {
        if (query.get('kp') && query.get('rt') && query.get('dk') && query.get('lu')) { 
          const keyPrefix = query.get('kp')               
          const lastUserId = query.get('lu')    

          Cookies.set(`${KEY_PREFIX_PROVIDER}`, keyPrefix, { domain: process.env.REACT_APP_DOMAIN })
          Cookies.set(`${keyPrefix}.LastAuthUser`, lastUserId, { domain: process.env.REACT_APP_DOMAIN })
          Cookies.set(`${keyPrefix}.${lastUserId}.refreshToken`, query.get('rt'), { domain: process.env.REACT_APP_DOMAIN })
          Cookies.set(`${keyPrefix}.${lastUserId}.deviceKey`, query.get('dk'), { domain: process.env.REACT_APP_DOMAIN })

          renewToken(query.get('rt'), query.get('dk')).then(renew => {
              updateSession(renew.IdToken, renew.AccessToken, renew.ExpiresIn)
              setAuthState('RELOAD')                 
              resolve()    
          }).catch(err => {
              reject(err)
          })
        } else {  
            reject('No user session!')
        }  
      })

    })

    hydrate().catch(err => {
      signOut().then()   
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const getSession = () => {
    const keyPrefix = Cookies.get(`${KEY_PREFIX_PROVIDER}`)                
    const lastUserId = Cookies.get(`${keyPrefix}.LastAuthUser`)      
    const idToken = lastUserId && Cookies.get(`${keyPrefix}.${lastUserId}.idToken`)
    const accessToken = lastUserId && Cookies.get(`${keyPrefix}.${lastUserId}.accessToken`)
    const refreshToken = lastUserId && Cookies.get(`${keyPrefix}.${lastUserId}.refreshToken`)
    const deviceKey = lastUserId && Cookies.get(`${keyPrefix}.${lastUserId}.deviceKey`)
    return {idToken,accessToken,refreshToken,deviceKey,keyPrefix,lastUserId}
  }

  const renewToken = (refreshToken, deviceKey) => {
    return new Promise((resolve, reject) => {
      const params = {
        ClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
          DEVICE_KEY: deviceKey,
        },
      }
      cognitoApi.post('', params).then((response) => {
          resolve(response.data.AuthenticationResult)
      }).catch((err) => {
          reject(err)
      })
    })
  }

  const updateSession = (idToken, accessToken, expiresIn) => {
    const keyPrefix = Cookies.get(`${KEY_PREFIX_PROVIDER}`)            
    if (keyPrefix) {
      const lastUserId = Cookies.get(`${keyPrefix}.LastAuthUser`)      
      Cookies.set(`${keyPrefix}.${lastUserId}.idToken`, idToken, {expires: expiresIn, domain: process.env.REACT_APP_DOMAIN })
      Cookies.set(`${keyPrefix}.${lastUserId}.accessToken`, accessToken, {expires: expiresIn, domain: process.env.REACT_APP_DOMAIN })
    }
  }

  const renderStore = isLoading ?
    <LoadingOverlay visible={true} />
    :
    <UserContext.Provider value={{
      // user,
      refreshToken,
      idToken,
      authState,
      currentUser,
      setCurrentUser,
      settings,
      getSession,
      api,
      authApi,
      signOut,
      registryLog,
      updateProfile,
      helpClientEmail
    }}>
      {children}
    </UserContext.Provider>

  return renderStore
}
