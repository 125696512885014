import React, { useMemo, useContext, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { UserContext } from 'stores/UserStore'
import { FlexView, Icon } from 'components/common'
import styled from 'styled-components'
import AppLinkSelect from 'components/home/AppLinkSelect'
import { Manager, Reference, Popper } from 'react-popper'

const InfoButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  transition: opacity 0.2s;
`

const Wrapper = styled(FlexView)`
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  background:${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadows.low};
  margin: 4px !important;
  transition: all 0.2s;

  width: 10rem;
  min-height: 5rem;
    img {
      height:2.8rem;
      width: 2.8rem;
    }
    font-size: 0.8rem;
    
  & > ${FlexView} {
    transition: all 0.2s;
    filter: grayscale(100%);
    cursor: pointer;
    user-select: none;
    font-size: 0.9rem;
  }

  ${InfoButton} {
    opacity: 0;
  }

  &:hover {
    & > ${FlexView} {
      filter: grayscale(0%);
    }

    box-shadow: ${({ theme }) => theme.boxShadows.small};

    ${InfoButton} {
      opacity: 1;
    }
  }

  

  @media screen and (max-width: 1280px) {
    width: 9rem;
    min-height: 5rem;
    img {
      height:2.8rem;
      width: 2.8rem;
    }
    & > ${FlexView} {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 1080px) {
    width: 8rem;
    min-height: 4rem;
    img {
      height:2.6rem;
      width: 2.6rem;
    }
    & > ${FlexView} {
      font-size: 0.7rem;
    }
  }

  @media screen and (max-width: 880px) {
    width: 7rem;
    min-height: 3rem;
    img {
      height:2.2rem;
      width: 2.2rem;
    }
    & > ${FlexView} {
      font-size: 0.6rem;
    }
  }

  @media screen and (max-width: 680px) {
    width: 5.5rem;
    min-height: 1.5rem;
    img {
      height:2rem;
      width: 2rem;
    }
    & > ${FlexView} {
      font-size: 0.5rem;
    }
  }

  @media screen and (max-width: 480px) {
    width: 5rem;
    min-height: 1rem;
    img {
      height:1.8rem;
      width: 1.8rem;
    }
    & > ${FlexView} {
      font-size: 0.48rem;
    }
  }
`

const AppCard = ({ appId, text, onCardInfoClick }) => {
  const node = useRef()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser, getSession } = useContext(UserContext)
  const app = useMemo(() => currentUser && currentUser.applications.find(x => x.id === appId), [appId, currentUser])
  const linkOptions = useMemo(() => _.get(app, 'links', []).map((link, index) => ({
    value: index,
    label: `${t(link.label)}`
  })), [app, t])

  const onInfoClick = e => {
    e.stopPropagation()
    onCardInfoClick(app.label, text)
  }

  const onLinkSelect = linkIndex => {
    let redirectPath = app.links[linkIndex].url;
    const session = getSession()
    if (session.keyPrefix && session.lastUserId) {      
      if (redirectPath.indexOf(process.env.REACT_APP_DOMAIN) === -1 && redirectPath.indexOf(process.env.REACT_APP_LOGIN_DOMAIN) === -1) {
        redirectPath += '?kp=' + session.keyPrefix + 
          '&lu=' + session.lastUserId + 
          '&rt=' + session.refreshToken + 
          '&dk=' + session.deviceKey + 
          ''
      }
    }
    window.location.href = redirectPath
  }

  const onAppClick = () => {
    if (!app.links || !app.links[0]) {
      let redirectPath = app.link;
      const session = getSession()
      if (session.keyPrefix && session.lastUserId) {      
        if (redirectPath.indexOf(process.env.REACT_APP_DOMAIN) === -1 && redirectPath.indexOf(process.env.REACT_APP_LOGIN_DOMAIN) === -1) {
          redirectPath += '?kp=' + session.keyPrefix + 
            '&lu=' + session.lastUserId + 
            '&rt=' + session.refreshToken + 
            '&dk=' + session.deviceKey + 
            ''
        }
      }
      window.location.href = redirectPath
    }
    else {
      setIsOpen(true)
    }
  }

  const handleOutsideClick = e => {
    node && node.current && !node.current.contains(e.target) && setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  return app ?
    <Manager>
      <div ref={node}>
        <Reference>
          {({ ref }) => (
            <Wrapper ref={ref} onClick={!app.link || app.link === "https://www.vallsmart.com/" ? onInfoClick : onAppClick}>
              <InfoButton>
                <Icon name="info" width="24px" height="24px" onClick={onInfoClick} />
              </InfoButton>
              <img src={app.icon} alt={app.label} />
              <FlexView fontWeight="bold" fontSize="big" color="primary" margin="16px 0px 0px 0px">{app.label}</FlexView>
            </Wrapper>
          )}
        </Reference>
        <Popper placement="right">
          {({ ref, style, placement, scheduleUpdate }) => (
            <AppLinkSelect {...{ ref, style, scheduleUpdate, placement, isOpen, options: linkOptions, onSelect: onLinkSelect }} />
          )}
        </Popper>
      </div>
    </Manager> : null
}

export default AppCard