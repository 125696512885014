import React, { useContext } from 'react'
import { AppContext } from '../../stores/AppStore'
import { UserContext } from '../../stores/UserStore'
import { Redirect, Route } from 'react-router'

const PrivateRoute = ({ children, ...rest }) => {
  const { authState } = useContext(UserContext)
  const { setRedirectUrl } = useContext(AppContext)
  const isAuthenticated = authState === 'SIGNED_IN'

  !isAuthenticated && setRedirectUrl(window.location.href)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect to={{pathname: '/login', state: { from: location } }} />         
        )
      }
    />
  )
}

export default PrivateRoute
