import React, { useContext} from 'react'
import { AppContext } from '../stores/AppStore'
import { UserContext } from '../stores/UserStore'

const Login = () => {
  const { redirectUrl } = useContext(AppContext)
  const { authState } = useContext(UserContext)

  if (authState === 'RELOAD') {
    window.location.replace(window.location.origin)
  } else if (authState === 'ACCESS_DENIED') {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/access-denied${redirectUrl ? `?redirectTo=${redirectUrl}` : ''}`
  } else {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/logout${redirectUrl ? `?redirectTo=${redirectUrl}` : ''}`    
  }
  return <></>
}

export default Login